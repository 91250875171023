<template>
  <SubHeader class="BomReportHeader">
    <template v-slot:leftSide>
      <h3>BOM Report</h3>
    </template>
    <template v-slot:rightSide>
      <button
        class="button download"
        @click="onDownloadReportClick"
      >
        Download Report
        <Spinner
          v-if="store.bomReport.isLoading"
          color="white"
        />
      </button>
    </template>
  </SubHeader>
</template>

<script>
import Spinner from '../Spinner.vue'
import SubHeader from '../SubHeader.vue'

export default {
  components: {
    Spinner,
    SubHeader
  },
  inject: [ 'store' ],
  methods: {
    onDownloadReportClick () {
      this.store.bomReport.download()
    }
  }
}
</script>

<style lang="scss">
.BomReportHeader {
  .download.button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Spinner {
      width: 16px;
      height: 16px;
      margin-left: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
