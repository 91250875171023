<template>
  <div>
    <!-- <div style="float:right">
      <button
        class="button"
        :style="float"
        @click="generateExcel()"
      >
        Export To Excel
      </button>
    </div> -->
    <AgGridVue
      v-if="showGridView"
      class="BomGrid ag-theme-balham"
      :column-defs="columnDefs"
      :row-data="rowData"
      :header-height="vars.headerHeight"
      :row-height="vars.rowHeight"
      :style="style"
      :grid-options="gridOptions"
      :suppress-cell-selection="true"
      :suppress-row-click-selection="true"
      :default-col-def="defaultColDef"
      :excel-styles="excelStyles"
      loading-overlay-component-framework="GridOverlay"
      no-rows-overlay-component-framework="GridOverlay"
    />
    <span v-else>{{ 'Please edit the search' }}</span>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import GridOverlay from '../BomGrid/GridOverlay.vue'
export default {
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    GridOverlay
  },
  inject: ['store'],
  props: {
    gridData: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      gridOptions: null,
      gridApi: null,
      rowSelection: null,
      excelStyles: null,
      defaultColDef: null
    }
  },
  computed: {
    currentNav () {
      return this.store.whereUsed.activeNav
    },
    columnDefs () {
      let columnVal = []
      if (this.currentNav === 'LINE ITEMS') {
        columnVal = [
          { headerName: 'PC9 Code',
            field: 'pc9',
            headerClass: 'sectionHeader',
            resizable: true,
            cellClass: 'sectionCell'
          },
          { headerName: 'Season',
            field: 'seasonName',
            headerClass: 'sectionHeader',
            resizable: true,
            cellClass: 'PC9Cell'
          },
          { headerName: 'Section',
            field: 'section',
            headerClass: 'sectionHeader',
            resizable: true,
            cellClass: 'sectionCell'
          },
          { headerName: 'Component',
            field: 'component',
            headerClass: 'sectionHeader',
            resizable: true,
            cellClass: 'sectionCell'
          },
          { headerName: 'Component Location',
            field: 'componentLocation',
            headerClass: 'sectionHeader',
            resizable: true,
            cellClass: 'sectionCell'
          }

        ]
      } else if (this.currentNav === 'PC9 SEASON') {
        columnVal = [
          { headerName: 'Season',
            field: 'pobjrefSeason',
            resizable: true,
            cellClass: 'PC9Cell',
            headerClass: 'sectionHeader'
          },
          { headerName: 'PC9 Code',
            field: 'pc9',
            resizable: true,
            cellClass: 'sectionCell',
            headerClass: 'sectionHeader'
          }
        ]
      } else if (this.currentNav === 'SUPPLIER COLOR') {
        columnVal = [
          { headerName: 'Vendor Name',
            field: 'vendorName',
            resizable: true,
            cellClass: 'sectionCell',
            headerClass: 'sectionHeader'
          },
          { headerName: 'Vendor ID',
            field: 'pobjrefVendorCode',
            resizable: true,
            cellClass: 'sectionCell',
            headerClass: 'sectionHeader'
          },
          { headerName: 'Color Code',
            field: 'pobjrefColorCode',
            resizable: true,
            cellClass: 'sectionCell',
            headerClass: 'sectionHeader'
          },
          { headerName: 'Color Name',
            field: 'colorName',
            resizable: true,
            cellClass: 'sectionCell',
            headerClass: 'sectionHeader'
          }

        ]
      }
      return columnVal
    },
    rowData () {
      let rowData = []
      if (this.currentNav === 'LINE ITEMS' && this.gridData.lineItems) {
        let updatedData = []
        let selectedSeasons = []
        selectedSeasons = this.store.whereUsed.params.seasons
        updatedData = this.gridData.lineItems.map(lineItem => {
          let extractInfo = []
          extractInfo = lineItem.bomlinkId.split('-')
          lineItem.pc9 = extractInfo[2].concat('-') + extractInfo[3]
          lineItem.season = extractInfo[0].concat('-').concat(extractInfo[1])
          return lineItem
        })
        if (selectedSeasons.length) {
          selectedSeasons.map(season => {
            rowData = updatedData.filter(el => {
              return el.season === season
            })
          })
        } else {
          rowData = updatedData
        }
      } else if (this.currentNav === 'PC9 SEASON' && this.gridData.pc9Seasons) {
        let modifiedData = []
        let selectedSeasons = []
        let selectedBrands = []
        let filteredData = []
        modifiedData = this.gridData.pc9Seasons.map(product => {
          let pc9Arrray = product.pc9SeasonId.split('-')
          product.pc9 = pc9Arrray[5].concat('-') + pc9Arrray[6]
          return product
        })
        selectedSeasons = this.store.whereUsed.params.seasons
        selectedBrands = this.store.whereUsed.params.brands
        if (selectedSeasons.length && selectedBrands.length) {
          selectedSeasons.map(season => {
            filteredData = modifiedData.filter(pc9 => {
              return pc9.pc9SeasonId.includes(season)
            })
          })
          selectedBrands.map(brand => {
            rowData = filteredData.filter(pc9 => {
              return pc9.lscoBrand === brand
            })
          })
        } else if (selectedSeasons.length) {
          selectedSeasons.map(season => {
            rowData = modifiedData.filter(pc9 => {
              return pc9.pc9SeasonId.includes(season)
            })
          })
        } else if (selectedBrands.length) {
          selectedBrands.map(brand => {
            rowData = modifiedData.filter(pc9 => {
              return pc9.lscoBrand === brand
            })
          })
        } else {
          rowData = modifiedData
        }
      } else if (this.currentNav === 'SUPPLIER COLOR' && this.gridData.materialColors) {
        if (this.store.whereUsed.selectedColor) {
          rowData = this.gridData.materialColors.filter(matcol => {
            return matcol.colorName === this.store.whereUsed.selectedColor
          })
        } else if (this.store.whereUsed.selectedVendor) {
          rowData = this.gridData.materialColors.filter(matcol => {
            return matcol.vendorName === this.store.whereUsed.selectedVendor
          })
        } else if (this.store.whereUsed.selectedVendor && this.store.whereUsed.selectedColor) {
          rowData = this.gridData.materialColors.filter(matcol => {
            return (matcol.colorName === this.store.whereUsed.selectedColor) && (matcol.vendorName === this.store.whereUsed.selectedVendor)
          })
        } else {
          rowData = this.gridData.materialColors
        }
      }
      return rowData
    },
    showGridView () {
      return (this.gridData)
    },
    style () {
      return {
        '--sub-cell-font-size': `${this.vars.subCellFontSize}px`,
        '--sub-cell-padding': `${this.vars.subCellPadding}px`,
        '--sub-cell-height': `${this.vars.subCellHeight}px`,
        '--resizer-height': `${this.vars.resizerHeight}px`,
        '--row-height': `${this.vars.rowHeight}px`
      }
    },
    vars () {
      const subCellFontSize = 6
      const subCellPadding = 4
      const subCellHeight = subCellFontSize + subCellPadding * 2
      const rowHeight = subCellHeight * 5 + 3
      const headerHeight = 60
      const resizerMarginTop = 8 // defined in the grid theme
      const resizerHeight = headerHeight - resizerMarginTop * 2

      return {
        subCellFontSize,
        subCellPadding,
        subCellHeight,
        headerHeight,
        resizerHeight,
        rowHeight
      }
    }
  },
  beforeMount () {
    this.gridOptions = {}
    // this.gridOptions.api.sizeColumnsToFit()
  },
  mounted () {
    this.gridApi = this.gridOptions.api
  },
  methods: {
    generateExcel () {
      this.gridApi.exportDataAsExcel()
    }
  }
}
</script>

<style lang="scss">
.BomGrid {
  width: 100%;
  height: 50rem;

  .button {
    margin: 2px;
  }

  .ag-root {
    .ag-pinned-left-header {
      border-right-width: 4px;
    }

    .sectionHeader {
      width: 100%;
      height: 100%;

      .ag-header-cell-text {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 16px;
      }
    }

    .ag-header-cell.seasonHeader {
      width: 20px;
    }

    .PC9Cell {
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 16px;
      font-family: "Overpass", sans-serif;
      font-weight: 500;
    }

    .sectionCell {
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: "Overpass", sans-serif;
      font-weight: 500;
    }

    .ag-header-row {
      width: 100%;
    }

    .ag-header-cell::after,
    .ag-header-group-cell::after {
      height: var(--resizer-height);
    }

    .ag-header-cell {
      h1 {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }

      h2 {
        font-size: 16px;
        font-weight: bold;
      }

      .ag-header-cell-menu-button {
        cursor: pointer;
      }
    }

    .ag-row-selected {
      background-color: unset !important;
    }
  }
}
</style>
