<template>
  <div class="flex BomReportPC5Code">
    <input
      v-model="pc5codes"
      type="text"
    >
  </div>
</template>

<script>
import whitespaceToCommas from '../../util/whitespaceToCommas.js'

export default {
  inject: [ 'store' ],
  computed: {
    pc5codes: {
      get () {
        return this.store.bomReport.params.productCode
      },
      set (value) {
        const PC5Code = whitespaceToCommas(value)
        this.store.bomReport.params.productCode = PC5Code === '' || PC5Code === null ? null : PC5Code
      }
    }
  }
}
</script>
<style lang="scss">
.BomReportPC5Code {
  input {
    width: 23vw;
  }
}
</style>
