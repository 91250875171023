<template>
  <div class="AllSeasons">
    <CheckOptions
      :model="store.whereUsed.params"
      name="seasons"
      :options="options"
    />
  </div>
</template>

<script>
import CheckOptions from '../CheckOptions.vue'

export default {
  components: {
    CheckOptions
  },
  inject: [ 'store' ],
  computed: {
    options () {
      return this.years.reduce((acc, year) => {
        this.seasons.forEach((season) => {
          const value = `${season.value}-${year.value}`
          const label = `${year.label} ${season.value}`
          acc.push({
            value,
            label
          })
        })
        return acc
      }, [])
    },
    seasons () {
      return this.store.seasonSelector.seasonOptions
    },
    style () {
      return {
        '--row-count': Math.ceil(this.options.length / 2)
      }
    },
    years () {
      return this.store.seasonSelector.yearOptionsWith2020
    }
  }
}
</script>
