<template>
  <div class="AutoComplete">
    <select v-model="value">
      <option>{{ "Select vendor" }}</option>
      <option
        v-for="(result,index) in supplier"
        :key="index"
      >
        {{ result }}
      </option>
    </select>
    <!-- <Spinner v-if="lookup.isLoading" /> -->
    <!-- <span>{{ supplierData }}</span> -->
  </div>
</template>

<script>

export default {
  inject: [ 'store' ],
  computed: {
    value: {
      get () {
        return this.store.whereUsed.vendor
      },
      set (value) {
        this.store.whereUsed.vendor = value
      }
    },
    supplier () {
      return this.store.whereUsed.supplier
    }
  }
}
</script>

<style lang="scss">
.AutoComplete {
  position: relative;

  input {
    width: 100%;
  }

  .Spinner {
    position: absolute;
    right: 8px;
    top: 8px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .label {
    height: 14px;
    margin: 1rem 1rem 0 1rem;
    font-size: 13px;
    color: $gray-inactive;
    width: calc(100% - 2rem);
    overflow: hidden;

    span {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.gridMode {
    display: flex;
    height: 100%;

    input {
      height: 100%;
      padding: 0 4px;
      border: none;
      box-shadow: none;
      border-radius: 0;
      font-size: 14px;
    }

    .Spinner {
      right: 4px;
      top: 4px;

      svg {
        height: 14px;
        width: 14px;
      }
    }

    .label {
      display: none;
    }
  }
}
</style>
