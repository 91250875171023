<template>
  <div
    class="whereUsedMaterial"
    @submit.prevent="onSubmit"
  >
    <AutoComplete
      ref="materialCode"
      type="material"
      value-key="lscoMaterialCode"
      labeler="materialName"
      :model="store.findMaterial"
      name="material"
      focus
      @resultSelected="onSubmit"
    />
  </div>
</template>

<script>
import AutoComplete from '../AutoComplete.vue'

export default {
  components: {
    AutoComplete
  },
  inject: [ 'store' ],
  watch: {
    'store.findMaterial.pane': function (newValue, oldValue) {
      if (newValue === 'materialCode') {
        this.$refs.materialCode.setFocus()
        this.$refs.materialCode.selectText()
      }
    }
  },
  methods: {
    onSubmit () {
      // this.store.findMaterial.nextPane()
      this.$refs.materialCode.blur()
      this.store.whereUsed.currentMaterial(this.store.findMaterial.material)
      this.$emit('selectedMaterial', this.store.findMaterial.material)
    }
  }
}
</script>
