<template>
  <div class="BomReportAvailable">
    <RadioGroup
      :model="store.bomReport.params"
      name="available"
      :options="options"
    />
  </div>
</template>

<script>
import RadioGroup from '../RadioGroup.vue'

export default {
  components: {
    RadioGroup
  },
  inject: [ 'store' ],
  computed: {
    options () {
      return this.store.codes.available
    }
  }
}
</script>
