<template>
  <div class="BomReportParams">
    <form @submit.prevent>
      <div
        v-if="!store.bomReport.isValid && store.bomReport.showValidationError"
        class="error"
      >
        Please choose a value for each field and try again.
      </div>
      <div
        v-if="store.bomReport.showDownloadError"
        class="error"
      >
        {{ showErrorMessage }}
      </div>
      <div class="params">
        <div class="param">
          <label>Season</label>
          <BomReportSeason />
        </div>

        <div class="param">
          <label>Brand</label>
          <BomReportBrands />
        </div>

        <div class="param">
          <label>Available</label>
          <BomReportAvailable />
        </div>

        <div class="param">
          <label>Product Category</label>
          <BomReportProductCategory />
        </div>

        <div class="param">
          <label>Gender</label>
          <BomReportGender />
        </div>
        <div class="param">
          <label>Power Core</label>
          <BomPowerCore
            header=""
            :model="store.bomReport.params"
          />
        </div>
        <div class="flex">
          <div class="param">
            <label>PC9 Code</label>
            <BomReportPC9Code />
          </div>
          <div class="param productCode">
            <label>PC5 Code</label>
            <BomReportPC5Code />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import BomReportAvailable from './BomReportAvailable.vue'
import BomReportBrands from './BomReportBrands.vue'
import BomReportGender from './BomReportGender.vue'
import BomReportProductCategory from './BomReportProductCategory.vue'
import BomReportSeason from './BomReportSeason.vue'
import BomReportPC9Code from './BomReportPC9Code.vue'
import BomReportPC5Code from './BomReportPC5Code.vue'
import BomPowerCore from '../BomPowerCore.vue'

export default {
  components: {
    BomReportAvailable,
    BomReportBrands,
    BomReportGender,
    BomReportProductCategory,
    BomReportSeason,
    BomReportPC9Code,
    BomReportPC5Code,
    BomPowerCore
  },
  inject: [ 'store' ],
  computed: {
    showErrorMessage () {
      return this.store.bomReport.showErrorMessage
    }
  }
}
</script>

<style lang="scss">
.BomReportParams {
  @include centerColumn();

  form {
    @include cardFull();

    .error {
      color: $red;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .params {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      overflow-x: auto;

      .param {
        flex: 0 0 auto;
        white-space: nowrap;
        margin-right: 65px;
      }

      .param.productCode {
        padding-top: 30px;
      }
    }
  }
}
</style>
