<template>
  <div class="BomReportPC9Code">
    <input
      v-model="pc9codes"
      type="text"
    >
  </div>
</template>

<script>
import whitespaceToCommas from '../../util/whitespaceToCommas.js'

export default {
  inject: [ 'store' ],
  computed: {
    pc9codes: {
      get () {
        return this.store.bomReport.params.pc9Code
      },
      set (value) {
        const PC9Code = whitespaceToCommas(value)
        this.store.bomReport.params.colorwayCode = PC9Code === '' || PC9Code === null ? null : PC9Code
      }
    }
  }
}
</script>
<style lang="scss">
.BomReportPC9Code {
  input {
    width: 23vw;
  }
}
</style>
