<template>
  <div class="AllBrands">
    <CheckOptions
      :model="store.whereUsed.params"
      name="brands"
      :options="options"
    />
  </div>
</template>

<script>
import CheckOptions from '../CheckOptions.vue'

export default {
  components: {
    CheckOptions
  },
  inject: [ 'store' ],
  computed: {
    options () {
      return this.store.seasonSelector.brandOptions
    }
  }
}
</script>
