<template>
  <div class="BomReport page">
    <WhereUsedHeader />
    <Scroller>
      <WhereUsedParams />
    </Scroller>
  </div>
</template>

<script>
import WhereUsedParams from '../components/WhereUsed/WhereUsedParams.vue'
import WhereUsedHeader from '../components/WhereUsed/WhereUsedHeader.vue'
import Scroller from '../components/Scroller.vue'

export default {
  components: {
    WhereUsedParams,
    WhereUsedHeader,
    Scroller
  }
}
</script>
