<template>
  <header class="Header">
    <div class="splitter">
      <ul class="nav">
        <li>
          <a @click="changeNav($event)">
            Line Items
          </a>
        </li>
        <li>
          <a @click="changeNav($event)">
            PC9 Season
          </a>
        </li>
        <li>
          <a @click="changeNav($event)">
            Supplier Color
          </a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  components: {
  },
  data: function () {
    return {
      materialUsed: [],
      ColorUsed: [],
      SupplierUsed: []
    }
  },
  inject: [ 'store' ],
  methods: {
    changeNav (event) {
      this.store.whereUsed.currentNav = event.target.innerText
    }
  }
}
</script>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
header.Header {
  background: $gray;
  padding: 0;
  position: relative;
  font-weight: 500;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;

  .splitter {
    @include centerColumn();

    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav {
      display: flex;

      li {
        margin-right: 3.2rem;

        a {
          padding: 0;
          height: $headerHeight;
          display: flex;
          align-items: center;
          color: $gray-header-links;
          transition: $anim-trans-base;
          position: relative;
          font-size: 1.3rem;
          text-transform: uppercase;
          letter-spacing: 0.05rem;

          &:hover {
            color: $white;

            &::after {
              width: 100%;
              left: 0;
              opacity: 0.15;
            }
          }

          &::after {
            content: "";
            background: $gray-lines;
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 1px;
            width: 0;
            transition: all 0.25s;
            opacity: 0;
          }

          &.active {
            color: $white;

            &::after {
              width: 100%;
              background: $red;
              opacity: 1;
              left: 0;
            }
          }
        }
      }
    }
  }
}
</style>
