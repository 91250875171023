<template>
  <div class="ColorUsed">
    <select v-model="value">
      <option>{{ "Select Color" }}</option>
      <option
        v-for="(result,index) in ColorUsed"
        :key="index"
      >
        {{ result }}
      </option>
    </select>
  </div>
</template>

<script>

export default {
  inject: [ 'store' ],
  computed: {
    value: {
      get () {
        return this.store.whereUsed.color
      },
      set (value) {
        this.store.whereUsed.color = value
      }
    },
    ColorUsed () {
      return this.store.whereUsed.AllColorUsed
    }
  }
}
</script>
