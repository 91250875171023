<template>
  <div class="BomPowerCore">
    <h5 v-if="header.length">
      {{ header }}
    </h5>
    <RadioGroup
      :model="model"
      name="powerCoreIndicator"
      :options="options"
    />
  </div>
</template>

<script>
import RadioGroup from './RadioGroup.vue'

export default {
  components: {
    RadioGroup
  },
  inject: [ 'store' ],
  props: {
    header: {
      type: String,
      default: ''
    },
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    options () {
      return this.store.codes.powerCore
    }
  }
}
</script>
