<template>
  <div class="BomReport page">
    <BomReportHeader />
    <Scroller>
      <BomReportParams />
    </Scroller>
  </div>
</template>

<script>
import BomReportHeader from '../components/BomReport/BomReportHeader.vue'
import BomReportParams from '../components/BomReport/BomReportParams.vue'
import Scroller from '../components/Scroller.vue'

export default {
  components: {
    BomReportHeader,
    BomReportParams,
    Scroller
  }
}
</script>
