<template>
  <div class="MaterialRelationGraph">
    <RelationalNav />
    <GridView :grid-data="gridData" />
  </div>
</template>

<script>
import RelationalNav from './RelationalNav'
import GridView from './GridView'
export default {
  components: {
    RelationalNav,
    GridView
  },
  // data: function () {
  //   return {
  //     materialUsed: [],
  //     ColorUsed: [],
  //     SupplierUsed: []
  //   }
  // },
  inject: [ 'store' ],
  computed: {
    gridData () {
      return this.store.whereUsed.currentMaterialName
    }
  }
}
</script>

<style lang="scss">
.MaterialRelationGraph {
  // @include centerColumn();
  @include cardFull();
}
</style>
