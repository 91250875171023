<template>
  <div class="WhereUsedParams">
    <form @submit.prevent>
      <div class="params">
        <div class="param season">
          <label>Season</label>
          <AllSeasons />
        </div>

        <div class="param">
          <label>Brand</label>
          <AllBrands />
        </div>
        <div class="param">
          <label>Material*</label>
          <MaterialUsed @selectedMaterial="onSelection" />
        </div>
        <div class="param">
          <label>Supplier</label>
          <SupplierUsed />
        </div>
        <div class="param">
          <label>Color</label>
          <ColorUsed />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AllBrands from './AllBrands.vue'
import AllSeasons from './AllSeasons.vue'
import MaterialUsed from './MaterialUsed.vue'
import ColorUsed from './ColorUsed.vue'
import SupplierUsed from './SupplierUsed.vue'
export default {
  components: {
    AllBrands,
    AllSeasons,
    MaterialUsed,
    SupplierUsed,
    ColorUsed
  },
  data: function () {
    return {
      materialUsed: [],
      ColorUsed: [],
      SupplierUsed: []
    }
  },
  inject: [ 'store' ],
  computed: {
    allInfo () {
      return this.store.whereUsed.currentMaterialName
    }
  },
  watch: {
    allInfo: function (newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.updateValue()
      }
    }
  },
  methods: {
    onSelection () {
      alert('selected')
    },
    updateValue () {
      this.SupplierUsed = this.allInfo.materialColors.map(el => {
        if (el.vendorName) return el.vendorName
      })
    }
  }
}
</script>

<style lang="scss">
.WhereUsedParams {
  @include centerColumn();

  form {
    @include cardFull();

    .error {
      color: $red;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .params {
      display: grid;
      justify-content: center;
      margin-right: 2rem;
      grid-template-columns: repeat(auto-fill, minmax(144px, 1fr));
      grid-row-gap: 32px;
      grid-column-gap: 32px;

      .param.season {
        grid-column: span 1;
      }
    }
  }
}
</style>
