<template>
  <SubHeader class="WhereUsedHeader">
    <template v-slot:leftSide>
      <h3>Search Material</h3>
      <div class="requiredFields">
        * Required Fields
      </div>
    </template>
    <template v-slot:rightSide>
      <button
        class="button paint"
        @click="plotGraph"
      >
        Search
        <Spinner
          v-if="store.bomReport.isLoading"
          color="white"
        />
      </button>
    </template>
  </SubHeader>
</template>

<script>
import Spinner from '../Spinner.vue'
import SubHeader from '../SubHeader.vue'

export default {
  components: {
    Spinner,
    SubHeader
  },
  inject: [ 'store' ],
  methods: {
    plotGraph () {
      this.store.whereUsed.gotToResults()
    }
  }
}
</script>

<style lang="scss">
.WhereUsedHeader {
  .paint.button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Spinner {
      width: 16px;
      height: 16px;
      margin-left: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
