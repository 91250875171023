<template>
  <div class="BomReport page">
    <MaterialRelationHeader />
    <MaterialRelationStats />
  </div>
</template>

<script>
import MaterialRelationStats from '../components/MaterialRelations/MaterialRelationStats.vue'
import MaterialRelationHeader from '../components/MaterialRelations/MaterialRelationHeader.vue'

export default {
  components: {
    MaterialRelationStats,
    MaterialRelationHeader
  }
}
</script>
