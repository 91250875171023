<template>
  <SubHeader class="MaterialRelationHeader">
    <template v-slot:leftSide>
      <h3>Search Material</h3>
    </template>
  </SubHeader>
</template>

<script>
import SubHeader from '../SubHeader.vue'

export default {
  components: {
    SubHeader
  },
  inject: [ 'store' ]
}
</script>

<style lang="scss">
.MaterialRelationHeader {
  .paint.button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Spinner {
      width: 16px;
      height: 16px;
      margin-left: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
