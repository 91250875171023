<template>
  <div class="BomReportSeason">
    <RadioGroup
      :model="store.bomReport.params"
      name="season"
      :options="options"
      :style="style"
    />
  </div>
</template>

<script>
import RadioGroup from '../RadioGroup.vue'

export default {
  components: {
    RadioGroup
  },
  inject: [ 'store' ],
  computed: {
    options () {
      return this.years.reduce((acc, year) => {
        this.seasons.forEach((season) => {
          const value = `${year.value} ${season.value}`
          const label = `${year.label} ${season.value}`
          acc.push({
            value,
            label
          })
        })
        return acc
      }, [])
    },
    seasons () {
      return this.store.seasonSelector.seasonOptions
    },
    style () {
      return {
        '--row-count': Math.ceil(this.options.length / 2)
      }
    },
    years () {
      return this.store.seasonSelector.yearOptionsWith2020
    }
  }
}
</script>

<style lang="scss">
.BomReportSeason {
  height: 70vh;
  width: 27rem;
  overflow: auto;

  .RadioGroup {
    display: grid;
    grid-template-rows: repeat(var(--row-count), 1fr);
    grid-auto-flow: column;
  }
}
</style>
